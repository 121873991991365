import React from "react";
import { FormattedMessage, FormattedHTMLMessage,injectIntl, Link } from "gatsby-plugin-intl"
import { BsFillCalendarFill } from "react-icons/bs";

import styles from "./postInformation.module.css";

const PostInformation = ({
	date,
	timeToRead,
	authorInfo,
	defaultAuthorInfo, intl
}) => {
	

	const addAuthor = () => {
		if (authorInfo.author) {
			return <a href={authorInfo.authorUrl}>{authorInfo.author}</a>;
		}
		return (
			<a href={defaultAuthorInfo.authorUrl}>{defaultAuthorInfo.author}</a>
		);
	};

	return (
		<p className={styles.postInfo}>
			<BsFillCalendarFill /> {intl.formatMessage({ id: "Templates.PostInformation.date" })}:{" "}
			{intl.formatDate(date)}. {intl.formatMessage({ id: "Templates.PostInformation.ttr" })}:{" "}
			{timeToRead}{" "}
			{timeToRead > 1
				? intl.formatMessage({ id: "Templates.PostInformation.minutes" })
				: intl.formatMessage({ id: "Templates.PostInformation.minute" })}
			. {intl.formatMessage({ id: "Templates.PostInformation.by" })}: {addAuthor()}
		</p>
	);
};

export default injectIntl(PostInformation);