import React from "react";
import { FormattedMessage, FormattedHTMLMessage,injectIntl, Link } from "gatsby-plugin-intl"
// import { Button } from "react-bootstrap";
import { BsTagFill } from "react-icons/bs";

import styles from "./tags.module.css";

const Tags = ({ tags,intl }) => {
	

	const addTags = () => {
		return tags.map((tag) => (
			<button
				variant="info"
				size="sm"
				as={Link}
				to={`/tags/${tag.replace(" ", "-")}`}
				className="tw-font-bold tw-py-2 tw-px-2 tw-mx-1 tw-rounded tw-bg-green-200"
				key={tag}
			>
				{tag}
			</button>
		));
	};

	return (
		<div className={styles.tagsStyle}>
			{intl.formatMessage({ id: "Templates.Tags.headline", defaultMessage:"" })} <BsTagFill /> {addTags()}
		</div>
	);
};

export default injectIntl(Tags);