/**
 * Heise Shariff component for GDPR confrom Social Media Likes
 * based on original Heise JS/CSS components
 *
 * Helpful articles for integration of eternal vendor specific js libraries
 * See: https://stackoverflow.com/questions/60787998/adding-external-css-and-js-will-not-working-in-gatsby-js
 */

import React from "react"
import PropTypes from "prop-types"
//import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from 'react-helmet'
import { withPrefix, Link } from "gatsby"

import "../../static/js/vendor/shariff-3.2.1/shariff.complete.css"




const Shariff = ({ children}) => {
/*
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
*/
  return (
    <>
      <Helmet> 
        <script src={withPrefix('js/vendor/shariff-3.2.1/shariff.complete.js')} type="text/javascript"/>
      </Helmet>

      <aside>
        <p><br /></p>
        <p>Beim Teilen geben Sie Ihre persönlichen Informationen bekannt, siehe auch <Link to="/legal/datenschutz/#shariff">Datenschutzerklärung</Link>.</p>
        <p style={{ fontSize: '0.6vw', alignContent: 'center'}}>            
        </p>
        <div className="shariff grayscale" data-orientation="horizontal" data-theme="black" data-lang="de" data-services="[&quot;whatsapp&quot;,&quot;facebook&quot;,&quot;xing&quot;,&quot;linkedin&quot;print&quot;]" data-button-style="icon">
        </div>    
       
      </aside>

    </>
  )
}

Shariff.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Shariff