import React from "react";
import { graphql } from "gatsby";
import { FormattedMessage, FormattedHTMLMessage,injectIntl, Link } from "gatsby-plugin-intl"

import PropTypes from "prop-types"

import { MDXRenderer } from 'gatsby-plugin-mdx'
import { MDXProvider } from '@mdx-js/react'

import Layout from "../components/layout";
import Tags from "../components/tags";
import SEO from "../components/seo/seo";
import PostInformation from "../components/postInformation";
import TwFrame from "../components/Style/TwFrame"

import Shariff from "../components/shariff"


const tagWithClassName = (Tag, className) => ({ children, ...props }) => (
	<Tag {...props} className={className}>
	  {children}
	</Tag>
  );


const BlogPost = ({ data, pageContext,intl }) => {
	const post = data.mdx;



	const renderTags = () => {
		return post.frontmatter.tags ? (
			<Tags tags={post.frontmatter.tags} />
		) : (
			<div></div>
		);
	};

	/* image= {pageContext.ogImage.path || null}  */

	return (
		<Layout>			
			<SEO title={post.frontmatter.title} created={post.frontmatter.date}  description={post.excerpt} pathname={post.fields.slug} og_type='article'/>

			<h1 className="tw-text-3xl tw-font-bold tw-text-true-gray-800">{post.frontmatter.title}</h1>
			<PostInformation
				date={post.frontmatter.date}
				timeToRead={post.timeToRead}
				authorInfo={post.frontmatter}
				defaultAuthorInfo={data.site.siteMetadata}
			/>
			{renderTags()}
			<hr />	

			<div className="markdown">	
				<MDXProvider
				      components={{
						h1: tagWithClassName(
						  'h1',
						  'text-gray-800 mt-12 mb-4 -ml-1 text-xl font-extrabold tracking-wider'
						),
						h2: tagWithClassName(
						  'h2',
						  'text-gray-800 mt-12 mb-4 -ml-1 text-xl font-bold tracking-wide'
						),
						h3: tagWithClassName(
						  'h3',
						  'text-gray-800 mt-12 mb-4 -ml-1 text-lg font-bold'
						),
						h4: tagWithClassName(
						  'h4',
						  'text-gray-800 mt-12 mb-4 -ml-1 text-lg font-semibold'
						),
						h5: tagWithClassName(
						  'h5',
						  'text-gray-800 mt-12 mb-4 -ml-1 text-lg font-medium'
						),
						h6: tagWithClassName(
						  'h6',
						  'text-gray-800 mt-12 mb-4 -ml-1 text-base font-semibold'
						),
						p: tagWithClassName(
						  'p',
						  'text-gray-800 leading-relaxed font-light mt-2 mb-4'
						),
						a: tagWithClassName(
						  'a',
						  'text-blue-700 hover:text-blue-500 leading-relaxed font-medium mb-8'
						),
						blockquote: tagWithClassName(
						  'blockquote',
						  'border-solid border-l-4 border-gray-300 pl-4'
						),
						ul: tagWithClassName('ul', 'list-disc m-4'),
						ol: tagWithClassName('ol', 'list-decimal m-4'),
						li: tagWithClassName('li', 'text-gray-800 font-light my-1'),
						strong: tagWithClassName('strong', 'font-semibold'),
						em: tagWithClassName('em', 'italic'),
					  }}
					   >
					<MDXRenderer>{post.body}</MDXRenderer>
				</MDXProvider >
			</div>
			<div className="tw-appearance-none">          
				<Shariff />
			</div> 
			
		</Layout>
	);
};

export const query = graphql`
	query($slug: String!) {
		mdx(fields: { slug: { eq: $slug } }) {
			body
			frontmatter {
				title
				date
				tags
				author
				authorUrl										
			}				
			excerpt
			timeToRead
			fields {
						slug
						path
					}
		}
		site {
			siteMetadata {
				author
				authorUrl
			}
		}
	}
`;

export default injectIntl(BlogPost);